<template>
  <v-card-actions class="ma-2">
    <v-spacer v-if="right" />
    <v-btn v-if="!editable" width="260" color="primary" @click="$emit('update:editable', true)">Editar <v-icon class="ml-2">mdi-pencil</v-icon></v-btn>
    <v-btn v-if="editable" width="260" color="error" outlined @click="$emit('cancel')">Cancelar <v-icon class="ml-2">mdi-cancel</v-icon></v-btn>
    <v-btn v-if="editable" width="260" color="success" @click="$emit('save')">Salvar <v-icon class="ml-2">mdi-content-save</v-icon></v-btn>
    <v-btn v-if="extraButton" :color="colorExtraButton" :width="sizeExtraButton" @click="$emit('action')">
      {{ textExtraButton }} <v-icon class="mr-2">{{ iconExtraButton }}</v-icon>
    </v-btn>
  </v-card-actions>
</template>
<script>
export default {
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    extraButton: {
      type: Boolean,
      default: false
    },
    textExtraButton: {
      type: String,
      default: ''
    },
    iconExtraButton: {
      type: String,
      default: ''
    },
    colorExtraButton: {
      type: String,
      default: ''
    },
    sizeExtraButton: {
      type: Number,
      default: 260
    },
    right: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
